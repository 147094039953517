import { Container } from "typescript-ioc";
import { ReadyHelper } from "Events/Scripts/ReadyHelper";
import { SliderFactory } from "./SliderFactory";

import "../Styles/ori-slider.css";

declare global {
    interface IOri {
        sliderFactory?: SliderFactory;
    }
}

const sliderFactory = Container.get(SliderFactory);
// auto init of all sliders on page
Container.get(ReadyHelper).bindDomReady(() => sliderFactory.createAll());
// put sliderFactory to ori namespace
ori.sliderFactory = sliderFactory;
